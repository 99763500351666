import React, { useEffect } from "react";
import { getClientList, getServerVersion } from "../api/rest";
import { RootState } from "../modules";
import { useDispatch, useSelector } from "react-redux";
import { setClientVersion, setServerVersion } from "../modules/version";
import styled from "styled-components";
import BottomTabMenu from "../components/BottomTabMenu";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { closeDialog } from "../modules/dialog";
import TopMenu from "../components/TopMenu";
import { Config, Key } from "../sdlib/config";
import { DeviceType, setDeviceList } from "../modules/device";
import { updateScheduleInfo } from "../modules/schedule";

const Container = styled.div`
  position: absolute;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  padding: 20px;
  margin: 0px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f2f2f2;
  min-width: 700px;
  overflow: auto;
`;

const VersionContainer = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  color: #bebebe;
`;

function Main() {
  const { client, server } = useSelector((state: RootState) => state.version);
  const { open, result } = useSelector((state: RootState) => state.dialog);
  const dispatch = useDispatch();

  useEffect(() => {
    const schedule_version = new Date().getTime();
    const schedule_start_time = Config.load<string>(
      Key.STR_SCHEDULE_START_TIME
    );
    const schedule_end_time = Config.load<string>(Key.STR_SCHEDULE_END_TIME);
    const gmt_value = Config.load<string>(Key.STR_SCHEDULE_GMT_VALUE);
    dispatch(
      updateScheduleInfo(
        schedule_version,
        schedule_start_time,
        schedule_end_time,
        gmt_value
      )
    );

    dispatch(setClientVersion(process.env.REACT_APP_VERSION_CODE || ""));
    getServerVersion().then((r) => {
      const v = r.data.version.version;
      const server_version = `${v.build}.${v.major}.${v.minor}.${v.revision}`;
      dispatch(setServerVersion(server_version));
    });

    const resource_info = Config.load<string>(Key.STR_RESOURCE_INFO);
    getClientList().then((response) => {
      const data = response.data.data as [];

      const json = JSON.parse(resource_info);
      const new_list = data.filter((item) => item != null) as DeviceType[];

      const real_list = [] as DeviceType[];
      let region_list = new_list.map((item) => {
        return item.region;
      });
      region_list = region_list.filter((item, index) => {
        return region_list.indexOf(item) === index;
      });

      region_list.map((item: string) => {
        const filter_item = new_list
          .filter((device: DeviceType) => {
            return device.region === item;
          })
          .sort((a, b) => {
            return Number(b.startup_time) - Number(a.startup_time);
          });

        const new_item = filter_item[0];
        const client_id = new_item.client_id;

        real_list.push(
          json[client_id] != undefined
            ? { ...new_item, res_type: json[client_id].split(".")[0] }
            : new_item
        );
        return item;
      });

      dispatch(setDeviceList(real_list));
    });
  }, []);

  const onClickOk = () => {
    dispatch(closeDialog());
  };

  return (
    <Container>
      <Dialog open={open}>
        <DialogTitle>Result</DialogTitle>

        <DialogContent>{result}</DialogContent>
        <DialogActions>
          <Button onClick={onClickOk}>OK</Button>
        </DialogActions>
      </Dialog>

      <h1>Ecosystem Wall</h1>
      <VersionContainer>
        <div>GUI : {client}</div>
        <div>SERVER : {server}</div>
      </VersionContainer>

      <TopMenu />
      <BottomTabMenu />
    </Container>
  );
}

export default Main;
