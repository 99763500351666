import React, { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import {
  removeScheduleItem,
  ScheduleItemType,
  updateScheduleItem,
} from "../modules/schedule";
import CloseButton from "./CloseButton";

const Container = styled.div`
  background-color: #d9d9d9;
  margin: 20px 15px;
  padding: 20px 10px;
  border-radius: 10px;
`;

const ScheduleItemLine = styled.div`
  display: flex;
  background-color: transparent;
  margin: 5px 0px;
`;

const ScheduleItemInput = styled.input`
  background-color: transparent;
  /* flex: 1; */
  margin-left: 5px;
  border: 1px solid black;
`;

function ScheduleItem(data: ScheduleItemType) {
  const { id, res_name, type, transitionTime, duration, alias } = data;
  const dispatch = useDispatch();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateScheduleItem({
        ...data,
        [e.target.name]: e.target.value,
      })
    );
  };

  const onRemove = () => {
    dispatch(removeScheduleItem(id));
  };

  return (
    <Container>
      <CloseButton onClick={onRemove} />
      <ScheduleItemLine>
        <label>alias : </label>
        <ScheduleItemInput name="alias" value={alias} onChange={onChange} />
      </ScheduleItemLine>
      <ScheduleItemLine>
        <div>type : {type}</div>
      </ScheduleItemLine>
      <ScheduleItemLine>
        {type === "video" && <div>duration : {duration}</div>}
        {type === "image" && (
          <>
            <label>duration : </label>
            <ScheduleItemInput
              name="duration"
              type="number"
              value={duration}
              onChange={onChange}
            />
          </>
        )}
      </ScheduleItemLine>
      <ScheduleItemLine>
        <label>transitionTime : </label>
        <ScheduleItemInput
          name="transitionTime"
          type="number"
          value={transitionTime}
          onChange={onChange}
        />
      </ScheduleItemLine>
      <ScheduleItemLine>
        <div>res_name : {res_name}</div>
      </ScheduleItemLine>
    </Container>
  );
}

export default ScheduleItem;
