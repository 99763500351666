import { combineReducers } from "redux";
import version from "./version";
import timesync from "./timesync";
import schedule from "./schedule";
import dialog from "./dialog";
import device from "./device";

const rootReducer = combineReducers({
  version,
  timesync,
  schedule,
  dialog,
  device,
});
export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
