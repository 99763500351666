import { Tab, Tabs } from "@mui/material";
import React from "react";
import styled from "styled-components";
import TabsAssetMenu from "./TabsAssetMenu";
import TabsDeviceMenu from "./TabsDeviceMenu";
import TabsEditScheduleMenu from "./TabsEditScheduleMenu";
import TabsLightMenu from "./TabsLightMenu";
import TabsShowScheduleMenu from "./TabsShowScheduleMenu";

const Container = styled.div`
  position: relative;
  margin: 20px 0px;
  width: calc(100% - 40px);
  height: 60vh;
`;

const TabContainer = styled.div`
  background-color: white;
  height: calc(100% - 50px);
`;

function BottomTabMenu() {
  const [tab, setTab] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  return (
    <Container>
      <Tabs value={tab} indicatorColor="primary" onChange={handleChange}>
        <Tab label="Edit Asset Collection" style={{ textTransform: "none" }} />
        <Tab label="Edit Device" style={{ textTransform: "none" }} />
        <Tab label="Edit Light" style={{ textTransform: "none" }} />
        <Tab label="Edit Schedule" style={{ textTransform: "none" }} />
        <Tab label="Show Schedule" style={{ textTransform: "none" }} />
      </Tabs>

      <TabContainer>
        {tab === 0 && <TabsAssetMenu />}
        {tab === 1 && <TabsDeviceMenu />}
        {tab === 2 && <TabsLightMenu />}
        {tab === 3 && <TabsEditScheduleMenu />}
        {tab === 4 && <TabsShowScheduleMenu />}
      </TabContainer>
    </Container>
  );
}

export default BottomTabMenu;
