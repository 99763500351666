import { List, ListItem } from "@mui/material";
import React, { RefObject, useEffect, useRef, useState } from "react";
import Sortable from "sortablejs";
import styled from "styled-components";
import { dlog } from "../sdlib/sdutil";
import { createSchedule, getLastAssetCollection } from "../api/rest";
import ScheduleItem from "./ScheduleItem";
import {
  addScheduleItems,
  ScheduleItemType,
  setScheduleItems,
  updateScheduleInfo,
} from "../modules/schedule";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../modules";
import { ReactSortable } from "react-sortablejs";
import { openDialog } from "../modules/dialog";
import { Config, Key } from "../sdlib/config";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftMenu = styled.div`
  flex: 1;
  position: relative;
`;

const RightMenu = styled.div`
  flex: 1;
  position: relative;
`;

const ItemList = styled.ul`
  position: absolute;
  background-color: #f2f2f2;
  left: 25px;
  right: 25px;
  top: 60px;
  bottom: 60px;
  padding: 20px 10px;
  border-radius: 15px;
`;

const AssetContainer = styled.div`
  position: absolute;
  top: 25px;
  left: 30px;
  right: 30px;
  height: fit-content;
`;

const ScheduleInfoLine = styled.div`
  top: 35px;
  left: 30px;
  right: 30px;
  height: fit-content;
  display: flex;
  margin: 5px 0px;
`;

const ScheduleItemList = styled.div`
  position: absolute;
  background-color: #f2f2f2;
  left: 25px;
  right: 25px;
  top: 180px;
  bottom: 75px;
  border-radius: 15px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CommandButton = styled.div<{ isLeft?: boolean }>`
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  left: ${(props) => (props.isLeft ? "25px" : "none")};
  right: ${(props) => (!props.isLeft ? "25px" : "none")};
  padding: 10px;
  width: fit-content;
  height: fit-content;
  background-color: #d8e2dc;
`;

const ScheduleInfoContainer = styled.div`
  position: absolute;
  top: 35px;
  left: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
`;

type CustomListItemType = {
  $isSelect?: boolean;
  onClick?: () => void;
};

const CustomListItem = styled(ListItem)`
  background: ${(props: CustomListItemType) =>
    props.$isSelect ? "#5aa9e6" : "transparent"};
  cursor: pointer;
` as React.ComponentType<CustomListItemType>;

export type AssetType = {
  assetname: string;
  md5: string;
  url: string;
  is_selected: boolean;
  mime: string;
  fileinfo: {
    duration: number;
  };
};

function TabsEditScheduleMenu() {
  const dndRef: RefObject<HTMLDivElement> = useRef(null);
  const dispatch = useDispatch();
  const {
    items,
    schedule_start_time,
    schedule_end_time,
    gmt_value,
    schedule_version,
  } = useSelector((state: RootState) => state.schedule);
  const [list, setList] = useState([] as ScheduleItemType[]);

  const ref = useRef(0);

  const [data, setData] = useState({
    collection_version: "",
    collection_comment: "",
    asset_list: [] as AssetType[],
  });

  const [config, setConfig] = useState({
    schedule_version: 0,
    schedule_start_time: "",
    schedule_end_time: "",
    gmt_value: "",
  });

  useEffect(() => {
    setList(items);
  }, [items]);

  useEffect(() => {
    if (dndRef.current != null) {
      const sortable = Sortable.create(dndRef.current);
      dlog("Sort", sortable);
    }

    getLastAssetCollection().then((response) => {
      const last_data = response.data.data[0];

      if (last_data != undefined) {
        setData({
          ...data,
          collection_version: last_data.version,
          collection_comment: last_data.comment,
          asset_list: last_data.assets.filter(
            (item: AssetType) => !!item.assetname && !!item.mime && !!item.md5
          ),
        });
      }
    });

    setConfig({
      schedule_start_time,
      schedule_end_time,
      gmt_value,
      schedule_version,
    });
  }, []);

  const onSelect = (assetname: string) => {
    setData({
      ...data,
      asset_list: data.asset_list.map((item) => {
        return item.assetname === assetname
          ? { ...item, is_selected: !item.is_selected }
          : item;
      }),
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfig({
      ...config,
      [e.target.name]: e.target.value,
    });
  };

  const onAdd = () => {
    const list = data.asset_list
      .filter((item) => item.is_selected)
      .map((item) => {
        dlog("item", item.assetname, item.mime);
        let type = item.mime.split("/")[0];
        ref.current++;

        let duration =
          item.fileinfo.duration != null ? item.fileinfo.duration : 0;
        duration -= duration % 1000;

        return {
          id: ref.current,
          res_name: item.assetname,
          type,
          duration: Math.floor(duration) || 10000,
          transitionTime: 8000,
          alias: "",
        };
      });

    dispatch(addScheduleItems(list as ScheduleItemType[]));
  };

  const onCreateSchedule = () => {
    const datas = items as ScheduleItemType[];

    let start_time =
      schedule_start_time.indexOf(":") !== -1 ? schedule_start_time : "10:00";
    let end_time =
      schedule_end_time.indexOf(":") !== -1 ? schedule_end_time : "18:00";

    const scheduleJson = {
      version: schedule_version,
      start_time,
      end_time,
      gmt_value,
      schedules: list.map((item) => {
        return datas
          .filter((data) => {
            return data.id === item.id;
          })
          .map((item) => {
            return {
              ...item,
              alias: item.alias || item.res_name,
            };
          })[0];
      }),
    };

    dlog(JSON.stringify(scheduleJson));

    createSchedule(JSON.stringify(scheduleJson))
      .then(() => {
        dispatch(openDialog("[Success] Create Schedule!"));
      })
      .catch(() => {
        dispatch(openDialog("[Fail] Create Schedule!"));
      });

    dispatch(
      updateScheduleInfo(
        config.schedule_version,
        config.schedule_start_time,
        config.schedule_end_time,
        config.gmt_value
      )
    );

    Config.put(Key.STR_SCHEDULE_START_TIME, config.schedule_start_time);
    Config.put(Key.STR_SCHEDULE_END_TIME, config.schedule_end_time);
    Config.put(Key.STR_SCHEDULE_GMT_VALUE, config.gmt_value);
  };

  const onEnd = () => {
    dispatch(setScheduleItems(list));
  };

  return (
    <Container>
      <LeftMenu>
        <AssetContainer>
          <div>Last Asset Collection Data</div>
          <div>
            {data.collection_version} ({data.collection_comment})
          </div>
        </AssetContainer>
        <ItemList>
          <List>
            {data.asset_list.map((item) => (
              <CustomListItem
                key={item.assetname + " : " + item.md5}
                $isSelect={item.is_selected}
                onClick={() => onSelect(item.assetname)}
              >
                <div>{item.assetname}</div>
              </CustomListItem>
            ))}
          </List>
        </ItemList>
        <CommandButton onClick={onAdd}>Add</CommandButton>
      </LeftMenu>
      <RightMenu>
        <ScheduleItemList id="container" ref={dndRef}>
          <ReactSortable list={list} setList={setList} onEnd={onEnd}>
            {list.map((item) => (
              <ScheduleItem key={item.id} {...item} />
            ))}
          </ReactSortable>
        </ScheduleItemList>
        <CommandButton onClick={onCreateSchedule}>
          Create Schedule
        </CommandButton>

        <ScheduleInfoContainer>
          <ScheduleInfoLine>
            <label style={{ flex: 1 }}>Version : </label>
            <input
              name="schedule_version"
              style={{ flex: 3, marginLeft: "5px" }}
              onChange={onChange}
              value={config.schedule_version}
            />
          </ScheduleInfoLine>
          <ScheduleInfoLine>
            <label style={{ flex: 1 }}>StartTime : </label>
            <input
              name="schedule_start_time"
              style={{ flex: 3, marginLeft: "5px" }}
              onChange={onChange}
              value={config.schedule_start_time}
            />
          </ScheduleInfoLine>
          <ScheduleInfoLine>
            <label style={{ flex: 1 }}>EndTime : </label>
            <input
              name="schedule_end_time"
              style={{ flex: 3, marginLeft: "5px" }}
              onChange={onChange}
              value={config.schedule_end_time}
            />
          </ScheduleInfoLine>
          <ScheduleInfoLine>
            <label style={{ flex: 1 }}>Gmt : </label>
            <input
              name="gmt_value"
              style={{ flex: 3, marginLeft: "5px" }}
              onChange={onChange}
              value={config.gmt_value}
            />
          </ScheduleInfoLine>
        </ScheduleInfoContainer>
      </RightMenu>
    </Container>
  );
}

export default TabsEditScheduleMenu;
