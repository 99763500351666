const OPEN_DIALOG = "dialog/OPEN_DIALOG" as const;
const CLOSE_DIALOG = "dialog/CLOSE_DIALOG" as const;

export type DialogData = {
  open: boolean;
  result: string;
};

export const openDialog = (result: string) => ({
  type: OPEN_DIALOG,
  result,
});

export const closeDialog = () => ({
  type: CLOSE_DIALOG,
});

const initialState: DialogData = {
  open: false,
  result: "",
};

type DialogAction =
  | ReturnType<typeof openDialog>
  | ReturnType<typeof closeDialog>;

export default function dialog(state = initialState, action: DialogAction) {
  switch (action.type) {
    case OPEN_DIALOG:
      return {
        ...state,
        open: true,
        result: action.result,
      };
    case CLOSE_DIALOG:
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
}
