import { Chip, IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  createCollection,
  createSchedule,
  getLastAssetCollection,
  sendCommand,
  sendCommandToAll,
} from "../api/rest";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import ScheduleIcon from "@mui/icons-material/Schedule";
import TraceOnIcon from "@mui/icons-material/Visibility";
import TraceOffIcon from "@mui/icons-material/VisibilityOff";
import TimerOnIcon from "@mui/icons-material/Timer";
import TimerOffIcon from "@mui/icons-material/TimerOff";
import PlayIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import ResumeIcon from "@mui/icons-material/DoubleArrow";
import SyncIcon from "@mui/icons-material/Sync";
import { useSelector } from "react-redux";
import { RootState } from "../modules";
import { dlog } from "../sdlib/sdutil";
import { AssetType } from "./TabsEditScheduleMenu";
import { Config, Key } from "../sdlib/config";

const TopContainer = styled.div`
  position: relative;
  margin: 20px 0px;
  width: 100%;
  height: 250px;
  background-color: white;
  display: flex;
  align-items: center;
  /* padding: 0px 10px; */
  overflow-x: auto;
`;

const ItemContainer = styled.div`
  position: relative;
  width: fit-content;
  min-width: 160px;
  height: 180px;
  margin: 0px 10px;
  padding: 15px;
  border-radius: 20px;
  background-color: #f2f2f2;
`;

const TopLine = styled.div`
  display: flex;
  align-items: center;
`;

const HorizontalLine = styled.div`
  margin: 5px 0px;
  display: flex;
  align-items: center;
`;

const ButtonContainer = styled.div`
  /* position: absolute; */
  display: flex;
  /* right: 0; */
  /* top: 0; */
  bottom: 0;
`;

export const CustomChip = styled(Chip)({
  margin: "0px 5px",
});

const CustomTooltip = styled(Tooltip)({
  cursor: "default",
});

export type ClientType = {
  conn_id: string;
  client_id: string;
  agent: string;
  version: number;
  region: string;
  startup_time: string;
  res_type?: string;
};

function TopMenu() {
  const { devices, asset_list } = useSelector(
    (state: RootState) => state.device
  );
  const { schedule_start_time, schedule_end_time, gmt_value } = useSelector(
    (state: RootState) => state.schedule
  );
  const [trace, setTrace] = useState(false);
  const [clock, setClock] = useState(false);

  const calculateCollectionData = () => {
    let res_data = calculateResourceData();
    const md5_data = asset_list
      .filter((item) => {
        return res_data.indexOf(item.asset_name.split(".")[0]) !== -1;
      })
      .map((item) => {
        return item.md5;
      })
      .join(",");
    return md5_data;
  };

  const calculateResourceData = () => {
    return devices
      .filter((item) => {
        return item.res_type != undefined;
      })
      .map((item) => {
        return item.res_type;
      });
  };

  const onUpdateAssetToAll = () => {
    createCollection(calculateCollectionData(), `${new Date().getTime()}`).then(
      (response) => {
        devices.forEach((device) => {
          if (device.res_type != null) {
            sendCommand(device.res_type, device.conn_id);
          }
        });
      }
    );

    // sendCommandToAll("ASSET_UPDATE");
  };

  const onUpdateAsset = (connection_id: string) => {
    createCollection(calculateCollectionData(), `${new Date().getTime()}`).then(
      (response) => {
        let device = devices.filter(
          (item) => item.conn_id === connection_id
        )[0];

        if (device.res_type != undefined) {
          sendCommand(device.res_type, connection_id);
        }
      }
    );

    // sendCommand("ASSET_UPDATE", connection_id);
  };

  const onUpdateScheduleToAll = async () => {
    const data = calculateResourceData();

    const response = await getLastAssetCollection();
    const assets = response.data.data[0].assets;

    let size = data.length;
    data.forEach(async (res, index) => {
      if (res != undefined) {
        let asset_data = assets.filter((asset: AssetType) => {
          return asset.assetname.indexOf(res) != -1;
        })[0];

        if (asset_data != undefined) {
          let duration = asset_data.fileinfo.duration;
          const transition = Config.load<number>(
            Key.INT_SCHEDULE_TRANSITION_TIME
          );

          let json = {
            version: `${new Date().getTime()}`,
            platform: res,
            start_time: schedule_start_time,
            end_time: schedule_end_time,
            gmt_value,
            schedules: [
              {
                type: "video",
                alias: "",
                duration: duration - (duration % 1000),
                transitionTime: Number(transition),
                res_name: asset_data.assetname,
              },
            ],
          };

          try {
            const schedule_response = await createSchedule(
              JSON.stringify(json)
            );
            dlog("SS", schedule_response);
          } catch (e) {
            dlog("Error", e);
          }

          if (index == size - 1) {
            sendCommandToAll("SCHEDULE_UPDATE");
          }
        }
      }
    });
  };

  const onUpdateSchedule = async (connection_id: string) => {
    let device = devices.filter((item) => item.conn_id === connection_id)[0];

    if (device.res_type != null) {
      const resource = device.res_type;
      const response = await getLastAssetCollection();

      let asset_data;
      try {
        const assets = response.data.data[0].assets;
        asset_data = assets.filter((asset: AssetType) => {
          return asset.assetname.indexOf(resource) != -1;
        })[0];
      } catch (e) {
        const update_response = await createCollection(
          calculateCollectionData(),
          `${new Date().getTime()}`
        );

        const asset_response = await getLastAssetCollection();
        asset_data = asset_response.data.data[0].assets.filter(
          (asset: AssetType) => {
            return asset.assetname.indexOf(resource) != -1;
          }
        )[0];
      }

      let duration = asset_data.fileinfo.duration;
      const transition = Config.load<number>(Key.INT_SCHEDULE_TRANSITION_TIME);

      let json = {
        version: `${new Date().getTime()}`,
        platform: resource,
        start_time: schedule_start_time,
        end_time: schedule_end_time,
        gmt_value,
        schedules: [
          {
            type: "video",
            alias: "",
            duration: duration - (duration % 1000),
            transitionTime: Number(transition),
            res_name: asset_data.assetname,
          },
        ],
      };

      try {
        const schedule_response = await createSchedule(JSON.stringify(json));
        dlog("SS", schedule_response);
      } catch (e) {
        dlog("Error", e);
      }

      sendCommand("SCHEDULE_UPDATE", connection_id);
    }
  };

  const onToggleTrace = () => {
    const command = !trace ? "TRACE_ON" : "TRACE_OFF";
    sendCommandToAll(command);

    setTrace(!trace);
  };

  const onToggleClock = () => {
    const command = !clock ? "SHOW_CLOCK" : "HIDE_CLOCK";
    sendCommandToAll(command);

    setClock(!clock);
  };

  const getAgentColor = (agent: string) => {
    if (agent === "windows") {
      return "SteelBlue";
    } else if (agent === "raspberrypi") {
      return "IndianRed";
    } else if (agent === "android") {
      return "SeaGreen";
    } else if (agent === "ios") {
      return "DimGrey";
    }

    return "lightgrey";
  };

  return (
    <>
      <TopLine>
        <h3 style={{ marginRight: "10px" }}>Clients({devices.length})</h3>
        <CustomTooltip title="ASSET_UPDATE">
          <div>
            <IconButton onClick={onUpdateAssetToAll}>
              <DownloadIcon />
            </IconButton>
          </div>
        </CustomTooltip>
        <CustomTooltip title="SCHEDULE_UPDATE">
          <div>
            <IconButton onClick={onUpdateScheduleToAll}>
              <ScheduleIcon />
            </IconButton>
          </div>
        </CustomTooltip>
        <CustomTooltip title="TRACE">
          <div>
            <IconButton onClick={onToggleTrace}>
              {trace && <TraceOnIcon />}
              {!trace && <TraceOffIcon />}
            </IconButton>
          </div>
        </CustomTooltip>
        <CustomTooltip title="CLOCK">
          <div>
            <IconButton onClick={onToggleClock}>
              {clock && <TimerOnIcon />}
              {!clock && <TimerOffIcon />}
            </IconButton>
          </div>
        </CustomTooltip>

        <CustomTooltip title="PLAY" style={{ marginLeft: "auto" }}>
          <div>
            <IconButton onClick={() => sendCommandToAll("SCHEDULE_PLAY")}>
              <PlayIcon />
            </IconButton>
          </div>
        </CustomTooltip>
        <CustomTooltip title="STOP">
          <div>
            <IconButton onClick={() => sendCommandToAll("SCHEDULE_STOP")}>
              <StopIcon />
            </IconButton>
          </div>
        </CustomTooltip>
        <CustomTooltip title="RESUME">
          <div>
            <IconButton onClick={() => sendCommandToAll("SCHEDULE_RESUME")}>
              <ResumeIcon />
            </IconButton>
          </div>
        </CustomTooltip>
      </TopLine>

      <TopContainer>
        {devices.map((item) => (
          <ItemContainer key={item.conn_id}>
            <HorizontalLine>
              <CustomTooltip title="Client Alias">
                <div style={{ fontWeight: "bold" }}>{item.client_id}</div>
              </CustomTooltip>
            </HorizontalLine>

            <CustomTooltip title="Client Id">
              <HorizontalLine>
                <div>{item.region}</div>
              </HorizontalLine>
            </CustomTooltip>

            <CustomTooltip title="Startup Time">
              <HorizontalLine>
                <div>
                  {moment(Number(item.startup_time)).format(
                    "yyyy-MM-DD hh:mm:ss"
                  )}
                </div>
              </HorizontalLine>
            </CustomTooltip>

            <HorizontalLine style={{ margin: "10px -10px" }}>
              <CustomTooltip title="Version">
                <div>
                  <CustomChip label={item.version} />
                </div>
              </CustomTooltip>
              <CustomTooltip title="Agent">
                <div>
                  <CustomChip
                    label={item.agent}
                    color="secondary"
                    style={{ backgroundColor: getAgentColor(item.agent) }}
                  />
                </div>
              </CustomTooltip>
            </HorizontalLine>

            {item.res_type != undefined && (
              <CustomTooltip title="Resource">
                <div>
                  <CustomChip
                    label={item.res_type}
                    color="secondary"
                    style={{ backgroundColor: "#666666", margin: "0" }}
                  />
                </div>
              </CustomTooltip>
            )}

            <ButtonContainer>
              <CustomTooltip title="ASSET_UPDATE">
                <div>
                  <IconButton
                    onClick={() => onUpdateAsset(item.conn_id)}
                    disabled={item.res_type == undefined}
                  >
                    <DownloadIcon fontSize="small" />
                  </IconButton>
                </div>
              </CustomTooltip>
              <CustomTooltip title="SCHEDULE_UPDATE">
                <div>
                  <IconButton
                    onClick={() => onUpdateSchedule(item.conn_id)}
                    disabled={item.res_type == undefined}
                  >
                    <ScheduleIcon fontSize="small" />
                  </IconButton>
                </div>
              </CustomTooltip>
              <CustomTooltip title="TIME_SYNC">
                <div>
                  <IconButton
                    onClick={() => sendCommand("TIME_SYNC", item.conn_id)}
                  >
                    <SyncIcon fontSize="small" />
                  </IconButton>
                </div>
              </CustomTooltip>
            </ButtonContainer>
          </ItemContainer>
        ))}
      </TopContainer>
    </>
  );
}

export default TopMenu;
