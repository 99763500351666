import axios from "axios";
import { dlog, isDebug } from "../../sdlib/sdutil";

/**
 * API 기본 주소
 * 디버그모드인 경우에는 local 서버를 바라보게끔 설정
 */
export const base_url = isDebug() ? "http://210.96.208.133:7018" : "";
// export const base_url = isDebug() ? "http://192.168.1.22:44307" : "";

export function getServerVersion() {
  return axios.post(`${base_url}/api/Version`);
}

export function getAssetList() {
  return axios.get(`${base_url}/api/asset/get_asset_list`);
}

export function getAssetInfo(assetname: string) {
  return axios.get(`${base_url}/api/asset/get_asset_info`, {
    params: {
      assetname,
    },
  });
}

export function uploadAsset(file: File, assetname: string) {
  return axios.post(`${base_url}/api/asset/media_upload`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
      assetname,
      bool_overwrite: true,
    },
  });
}

export function changeAssetName(old_name: string, new_name: string) {
  let form = new FormData();
  form.append("old_assetname", old_name);
  form.append("new_assetname", new_name);
  return axios.post(`${base_url}/api/asset/change_asset_name`, form);
}

export function createCollection(hashlist: string, comment: string) {
  let form = new FormData();
  form.append("hashlist", hashlist);
  form.append("comment", comment);
  return axios.post(`${base_url}/api/asset/create_collection`, form);
}

export function getLastAssetCollection() {
  return axios.get(`${base_url}/api/asset/get_asset_collection`, {
    params: {
      count: 1,
    },
  });
}

export function createSchedule(json: string) {
  return axios.post(`${base_url}/api/schedule/create_schedule`, json, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

export function removeAsset(hashlist: string) {
  let form = new FormData();
  form.append("hashlist", hashlist);
  return axios.post(`${base_url}/api/asset/remove_asset`, form);
}

export function loadScheduleJson() {
  return axios.get(`${base_url}/api/schedule/get_schedule`, {
    params: {
      count: 1,
    },
  });
}

export function loadMultipleScheduleJson() {
  return axios.get(`${base_url}/api/schedule/get_schedule`, {
    params: {
      count: 10,
    },
  });
}

export function loadLightJson() {
  return axios.get(`${base_url}/api/schedule/get_ledschedule`);
}

export function updateLightJson(json: any) {
  return axios.post(`${base_url}/api/schedule/create_ledschedule`, json);
}

export function getClientList() {
  return axios.get(`${base_url}/api/client/get_client_list`);
}

export function sendCommandToAll(cmd: string) {
  let form = new FormData();
  form.append("cmd", cmd);
  return axios.post(`${base_url}/api/SignalRTest/server_cmd`, form);
}

export function sendCommand(cmd: string, connection_id: string) {
  let form = new FormData();
  form.append("cmd", cmd);
  form.append("connection_id", connection_id);
  return axios.post(`${base_url}/api/SignalRTest/server_cmd`, form);
}
