const SET_DEVICE_LIST = "device/SET_DEVICE_LIST" as const;
const SET_ASSET_LIST = "device/SET_ASSET_LIST" as const;

export type DeviceType = {
  conn_id: string;
  client_id: string;
  agent: string;
  version: number;
  region: string;
  startup_time: string;
  res_type?: string;
  is_selected?: boolean;
};

export type AssetType = {
  asset_name: string;
  md5: string;
  is_selected: boolean;
};

export type DeviceData = {
  devices: DeviceType[];
  asset_list: AssetType[];
};

export const setDeviceList = (device_list: DeviceType[]) => ({
  type: SET_DEVICE_LIST,
  device_list,
});

export const setAssetList = (asset_list: AssetType[]) => ({
  type: SET_ASSET_LIST,
  asset_list,
});

const initialState: DeviceData = {
  devices: [],
  asset_list: [],
};

type DeviceAction =
  | ReturnType<typeof setDeviceList>
  | ReturnType<typeof setAssetList>;

export default function device(state = initialState, action: DeviceAction) {
  switch (action.type) {
    case SET_DEVICE_LIST:
      return {
        ...state,
        devices: action.device_list,
      };
    case SET_ASSET_LIST:
      return {
        ...state,
        asset_list: action.asset_list,
      };
    default:
      return state;
  }
}
