import { dlog } from "../sdutil";
import { decryptText, encryptText } from "../sdutil";

const STORAGE_NAME = "IMFINE_SD_STORAGE";

let saved_storage_data: any;
interface IConfig {
  initialize(): void;
  put<T>(key: Key, value: T): void;
  load<T>(key: Key): T;
}

export enum Key {
  STR_TEST_VALUE = "STR_TEST_VALUE",
  STR_RESOURCE_INFO = "STR_RESOURCE_INFO",
  STR_SCHEDULE_START_TIME = "STR_SCHEDULE_START_TIME",
  STR_SCHEDULE_END_TIME = "STR_SCHEDULE_END_TIME",
  STR_SCHEDULE_GMT_VALUE = "STR_SCHEDULE_GMT_VALUE",
  INT_SCHEDULE_TRANSITION_TIME = "INT_SCHEDULE_TRANSITION_TIME",
  INT_TEST_VALUE = "INT_TEST_VALUE",
  BOOL_TEST_VALUE = "BOOL_TEST_VALUE",
}

const Defalut: any = {
  STR_TEST_VALUE: "Hello",
  STR_RESOURCE_INFO: "{}",
  STR_SCHEDULE_START_TIME: "10:00",
  STR_SCHEDULE_END_TIME: "18:00",
  STR_SCHEDULE_GMT_VALUE: "+9",
  INT_SCHEDULE_TRANSITION_TIME: 8000,
  INT_TEST_VALUE: 100,
  BOOL_TEST_VALUE: false,
};

export const Config = {} as IConfig;

Config.initialize = () => {
  dlog("SD CONFIG START");
  const data = localStorage.getItem(STORAGE_NAME) as string;
  if (data != null) {
    var originalText = decryptText(data);
    saved_storage_data = JSON.parse(originalText);
  }
};

Config.put = <T>(key: Key, value: T) => {
  saved_storage_data = { ...saved_storage_data, [key]: value };
  const data = JSON.stringify(saved_storage_data);
  localStorage.setItem(STORAGE_NAME, encryptText(data));
};

Config.load = <T>(key: Key) => {
  if (saved_storage_data && saved_storage_data[key]) {
    return saved_storage_data[key] as T;
  } else {
    return Defalut[key] as T;
  }
};
