import React from "react";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/CloseRounded";

const Container = styled.div`
  position: relative;
  margin-left: auto;
  width: 25px;
  height: 25px;
  margin-top: -10px;
  border-radius: 4px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const ScheduleItemClose = styled.div`
  position: relative;
  margin-left: auto;
`;

type CloseButtonType = {
  onClick: () => void;
};

function CloseButton({ onClick }: CloseButtonType) {
  return (
    <Container onClick={onClick}>
      <ScheduleItemClose />
      <CloseIcon style={{ color: "#BFBFBF", fontSize: "35px" }} />
    </Container>
  );
}

export default CloseButton;
