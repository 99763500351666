import { List, ListItem } from "@mui/material";
import React, { RefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sortable from "sortablejs";
import styled from "styled-components";
import { createSchedule, getAssetList } from "../api/rest";
import { RootState } from "../modules";
import { setDeviceList } from "../modules/device";
import { openDialog } from "../modules/dialog";
import { ScheduleItemType, updateScheduleInfo } from "../modules/schedule";
import { Config, Key } from "../sdlib/config";
import { dlog } from "../sdlib/sdutil";
import { CustomChip } from "./TopMenu";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftMenu = styled.div`
  flex: 1;
  position: relative;
`;

const RightMenu = styled.div`
  flex: 1;
  position: relative;
`;

const ItemList = styled.ul`
  position: absolute;
  background-color: #f2f2f2;
  left: 25px;
  right: 25px;
  top: 60px;
  bottom: 60px;
  padding: 20px 10px;
  border-radius: 15px;

  overflow-y: scroll;
`;

const ScheduleInfoLine = styled.div`
  top: 35px;
  left: 30px;
  right: 30px;
  height: fit-content;
  display: flex;
  margin: 5px 0px;
`;

const ResourceList = styled.div`
  position: absolute;
  background-color: #f2f2f2;
  left: 25px;
  right: 25px;
  top: 220px;
  bottom: 75px;
  border-radius: 15px;
  overflow-y: scroll;
  padding: 15px 5px;

  ::-webkit-scrollbar {
    display: none;
  }
`;

const CommandButton = styled.div<{ isLeft?: boolean }>`
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  left: ${(props) => (props.isLeft ? "25px" : "none")};
  right: ${(props) => (!props.isLeft ? "25px" : "none")};
  padding: 10px;
  width: fit-content;
  height: fit-content;
  background-color: #d8e2dc;
`;

const ScheduleInfoContainer = styled.div`
  position: absolute;
  top: 35px;
  left: 30px;
  right: 30px;
  display: flex;
  flex-direction: column;
`;

type CustomListItemType = {
  $isSelect?: boolean;
  onClick?: () => void;
};

export const CustomListItem = styled(ListItem)`
  background: ${(props: CustomListItemType) =>
    props.$isSelect ? "#5aa9e6" : "transparent"};
  cursor: pointer;
` as React.ComponentType<CustomListItemType>;

type ResourceType = {
  md5: string;
  asset_name: string;
  is_select: boolean;
};

function TabsDeviceMenu() {
  const dndRef: RefObject<HTMLDivElement> = useRef(null);
  const dispatch = useDispatch();
  const {
    schedule_start_time,
    schedule_end_time,
    gmt_value,
    schedule_version,
  } = useSelector((state: RootState) => state.schedule);
  const { devices } = useSelector((state: RootState) => state.device);

  const [data, setData] = useState({
    asset_list: [] as ResourceType[],
    collection_version: "",
    collection_comment: "",
  });

  const [config, setConfig] = useState({
    schedule_version: 0,
    schedule_start_time: "",
    schedule_end_time: "",
    gmt_value: "",
    transition_time: 8000,
  });

  useEffect(() => {
    dlog("Started...");

    if (dndRef.current != null) {
      const sortable = Sortable.create(dndRef.current);
      dlog("Sort", sortable);
    }

    getAssetList().then((res) => {
      const list = res.data.data.map(
        (item: { asset_name: string; md5: string }) => {
          return {
            ...item,
            is_selected: false,
          };
        }
      );
      setData({
        ...data,
        asset_list: list,
      });
    });

    const transition_time = Config.load<number>(
      Key.INT_SCHEDULE_TRANSITION_TIME
    );

    setConfig({
      schedule_start_time,
      schedule_end_time,
      gmt_value,
      schedule_version,
      transition_time,
    });
  }, []);

  const onSelectDevice = (region: string) => {
    const new_clienets = devices.map((item) => {
      return item.region === region
        ? { ...item, is_selected: !item.is_selected }
        : { ...item, is_selected: false };
    });
    dispatch(setDeviceList(new_clienets));
  };

  const onSelectResource = (md5: string) => {
    setData({
      ...data,
      asset_list: data.asset_list.map((item) => {
        return item.md5 === md5
          ? { ...item, is_select: !item.is_select }
          : { ...item, is_select: false };
      }),
    });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfig({
      ...config,
      [e.target.name]: e.target.value,
    });
  };

  const onApplyItem = () => {
    let selected_device = devices.filter((item) => {
      return item.is_selected;
    })[0];
    let selected_item = data.asset_list.filter((item) => {
      return item.is_select;
    })[0];

    if (selected_device != undefined && selected_item != undefined) {
      dlog(selected_device, selected_item);

      const prev_info = Config.load<string>(Key.STR_RESOURCE_INFO);
      let json = {} as any;
      if (prev_info.length > 0) {
        json = JSON.parse(prev_info);
      }

      json[selected_device.client_id] = selected_item.asset_name;
      const update_info = JSON.stringify(json);
      Config.put(Key.STR_RESOURCE_INFO, update_info);
      // dlog("UU", update_info);

      const new_clienets = devices.map((item) => {
        return item.is_selected
          ? { ...item, res_type: selected_item.asset_name.split(".")[0] }
          : item;
      });
      dispatch(setDeviceList(new_clienets));
    }

    dispatch(
      updateScheduleInfo(
        config.schedule_version,
        config.schedule_start_time,
        config.schedule_end_time,
        config.gmt_value
      )
    );

    Config.put(Key.STR_SCHEDULE_START_TIME, config.schedule_start_time);
    Config.put(Key.STR_SCHEDULE_END_TIME, config.schedule_end_time);
    Config.put(Key.STR_SCHEDULE_GMT_VALUE, config.gmt_value);
    Config.put(Key.INT_SCHEDULE_TRANSITION_TIME, config.transition_time);
  };

  return (
    <Container>
      <LeftMenu>
        <ItemList>
          <List>
            {devices.map((item, index) => (
              <CustomListItem
                key={index}
                $isSelect={item.is_selected}
                onClick={() => onSelectDevice(item.region)}
              >
                <div>{item.client_id}</div>

                {item.res_type != undefined && (
                  <CustomChip
                    label={item.res_type}
                    color="secondary"
                    style={{ backgroundColor: "#666666", marginLeft: "auto" }}
                  />
                )}
              </CustomListItem>
            ))}
          </List>
        </ItemList>
      </LeftMenu>
      <RightMenu>
        {devices.filter((item) => {
          return item.is_selected;
        }).length > 0 && (
          <>
            <ResourceList>
              {data.asset_list.map((item) => (
                <CustomListItem
                  key={item.md5}
                  $isSelect={item.is_select}
                  onClick={() => onSelectResource(item.md5)}
                >
                  <div>{item.asset_name.replace(".info", "")}</div>
                </CustomListItem>
              ))}
            </ResourceList>
          </>
        )}
        <CommandButton onClick={onApplyItem}>Apply</CommandButton>

        <ScheduleInfoContainer>
          <ScheduleInfoLine>
            <label style={{ flex: 1 }}>Version : </label>
            <input
              name="schedule_version"
              style={{ flex: 3, marginLeft: "5px" }}
              onChange={onChange}
              value={config.schedule_version}
            />
          </ScheduleInfoLine>
          <ScheduleInfoLine>
            <label style={{ flex: 1 }}>StartTime : </label>
            <input
              name="schedule_start_time"
              style={{ flex: 3, marginLeft: "5px" }}
              onChange={onChange}
              value={config.schedule_start_time}
            />
          </ScheduleInfoLine>
          <ScheduleInfoLine>
            <label style={{ flex: 1 }}>EndTime : </label>
            <input
              name="schedule_end_time"
              style={{ flex: 3, marginLeft: "5px" }}
              onChange={onChange}
              value={config.schedule_end_time}
            />
          </ScheduleInfoLine>
          <ScheduleInfoLine>
            <label style={{ flex: 1 }}>Gmt : </label>
            <input
              name="gmt_value"
              style={{ flex: 3, marginLeft: "5px" }}
              onChange={onChange}
              value={config.gmt_value}
            />
          </ScheduleInfoLine>

          <ScheduleInfoLine>
            <label style={{ flex: 1, color: "blue" }}>Transition : </label>
            <input
              name="transition_time"
              style={{ flex: 3, marginLeft: "5px" }}
              onChange={onChange}
              value={config.transition_time}
            />
          </ScheduleInfoLine>
        </ScheduleInfoContainer>
      </RightMenu>
    </Container>
  );
}

export default TabsDeviceMenu;
