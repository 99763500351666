import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  base_url,
  createCollection,
  getAssetList,
  removeAsset,
  uploadAsset,
} from "../api/rest";
import { openDialog } from "../modules/dialog";
import { dlog, isDebug } from "../sdlib/sdutil";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { setAssetList } from "../modules/device";
import { RootState } from "../modules";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftMenu = styled.div`
  flex: 1;
  position: relative;
`;

const RightMenu = styled.div`
  flex: 1;
  position: relative;
`;

const ItemList = styled.ul`
  position: absolute;
  background-color: #f2f2f2;
  left: 25px;
  right: 25px;
  top: 60px;
  bottom: 60px;
  padding: 20px 10px;
  border-radius: 15px;
  overflow-y: auto;
`;

const CommentContainer = styled.div`
  position: absolute;
  top: 35px;
  left: 30px;
  right: 30px;
  height: fit-content;
  display: flex;
`;

const CommandButton = styled.div<{ isLeft?: boolean }>`
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  left: ${(props) => (props.isLeft ? "25px" : "none")};
  right: ${(props) => (!props.isLeft ? "25px" : "none")};
  padding: 10px;
  width: fit-content;
  height: fit-content;
  background-color: #d8e2dc;
`;

const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  right: 10px;
`;

type CustomListItemType = {
  $isSelect?: boolean;
  $isPointer?: boolean;
  onClick?: () => void;
};

const CustomListItem = styled(ListItem)`
  background: ${(props: CustomListItemType) =>
    !!props.$isSelect ? "#5aa9e6" : "transparent"};
  cursor: ${(props: CustomListItemType) =>
    !!props.$isPointer ? "pointer" : "default"};
  display: flex;
  align-items: center;
  height: 40px;
` as React.ComponentType<CustomListItemType>;

type AssetType = {
  asset_name: string;
  md5: string;
  is_selected: boolean;
};

export const DeviceTypeList = [
  {
    label: "DESKTOP_MAIN",
    value: 0,
  },
  {
    label: "DESKTOP_BOOKPRO",
    value: 1,
  },
  {
    label: "MOBILE_ZFLIP3",
    value: 2,
  },
  {
    label: "MOBILE_S21FE",
    value: 3,
  },
  {
    label: "MOBILE_TABS7+",
    value: 4,
  },
  {
    label: "MOBILE_TABS7FE",
    value: 5,
  },
  {
    label: "WATCH4",
    value: 6,
  },
  {
    label: "SOUND_PLAYER",
    value: 7,
  },
];

function TabsAssetMenu() {
  const [data, setData] = useState({
    collection_list: [] as AssetType[],
    new_asset_name: "",
    new_asset_extension: "",
    new_asset_file: null as File | null,
    collection_comment: "",
  });
  const { asset_list } = useSelector((state: RootState) => state.device);

  const [dialog, setDialog] = useState({
    open: false,
    upload: false,
  });

  const [overItem, setOverItem] = useState("");

  const dispatch = useDispatch();

  const loadAssetList = () => {
    getAssetList().then((response) => {
      const list = response.data.data.map(
        (item: { asset_name: string; md5: string }) => {
          return {
            ...item,
            is_selected: false,
          };
        }
      );
      dispatch(setAssetList(list));
    });
  };

  useEffect(() => {
    loadAssetList();
  }, []);

  const onSelect = (md5: string) => {
    dispatch(
      setAssetList(
        asset_list.map((item) => {
          return item.md5 === md5
            ? { ...item, is_selected: !item.is_selected }
            : item;
        })
      )
    );
  };

  const onAddList = () => {
    setData({
      ...data,
      collection_list: asset_list.filter((item) => item.is_selected),
    });
  };

  const onUpdate = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.multiple = false;
    input.onchange = (e: any) => {
      if (input.files != null) {
        let file = input.files[0];
        dlog(file);

        dlog(file.name.split(".")[1]);
        dlog(data);

        setData({
          ...data,
          new_asset_extension: file.name.split(".")[1],
          new_asset_name: "",
          new_asset_file: file,
        });

        setDialog({
          open: true,
          upload: false,
        });
      }
    };
    // input.accept = ".jpg, .png, .mp4";
    input.accept = ".mp4";

    input.click();
  };

  const closeDialog = () => {
    setDialog({
      ...dialog,
      open: false,
    });
  };

  const onUpdateAsset = () => {
    let new_file_name = `${data.new_asset_name}.mp4`;
    const md5_list = asset_list.filter((item) => {
      if (item.asset_name.indexOf(new_file_name) != -1) {
        return item.md5;
      }
    });
    let md5_data = md5_list
      .map((item) => {
        return item.md5;
      })
      .join(",");

    dlog("MM", md5_data.length);

    // file upload
    dlog(data.new_asset_file);
    if (data.new_asset_file != null) {
      uploadAsset(data.new_asset_file, new_file_name)
        .then((response) => {
          dlog(response.data);

          if (md5_data.length > 0) {
            removeAsset(md5_data)
              .then((response) => {
                loadAssetList();
              })
              .catch(() => {
                dispatch(openDialog("[Fail] Remove Asset!"));
              });
          } else {
            const upload_data = response.data.data;
            dispatch(
              setAssetList([
                ...asset_list,
                {
                  asset_name: upload_data.assetname,
                  md5: upload_data.md5,
                  is_selected: false,
                },
              ])
            );
          }
        })
        .finally(() => {
          closeDialog();
        });
    }
  };

  const handleClose = () => {
    setDialog({
      open: false,
      upload: false,
    });
  };

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const onCreateCollection = () => {
    if (data.collection_list.length === 0) return;

    const md5_list = data.collection_list.filter((item) => {
      if (item.is_selected) {
        return item.md5;
      }
    });
    let md5_data = md5_list
      .map((item) => {
        return item.md5;
      })
      .join(",");

    createCollection(md5_data, data.collection_comment)
      .then((response) => {
        dlog(response);

        dispatch(openDialog("[Success] Create Collection!"));
      })
      .catch((err) => {
        dlog(err);

        dispatch(openDialog("[Fail] Create Collection!"));
      });

    dlog(md5_list);
    dlog(md5_data);
  };

  const onRemove = () => {
    if (asset_list.length === 0) return;

    const md5_list = asset_list.filter((item) => {
      if (item.is_selected) {
        return item.md5;
      }
    });
    let md5_data = md5_list
      .map((item) => {
        return item.md5;
      })
      .join(",");

    removeAsset(md5_data)
      .then((response) => {
        dispatch(openDialog("[Success] Remove Asset!"));
        loadAssetList();
      })
      .catch(() => {
        dispatch(openDialog("[Fail] Remove Asset!"));
      });
  };

  const onClickVideoPreview = (md5: string) => {
    let base = isDebug()
      ? `${base_url}/`
      : `${window.location}${window.location.pathname}/`.replace("///", "/");

    let url = `${base}assets/${md5}.mp4`.replace("///", "/");
    window.open(url, "_blank");
  };

  const onChangeOption = (event: any, value: any) => {
    setData({
      ...data,
      new_asset_name: value,
    });
  };

  return (
    <Container>
      <Dialog open={dialog.open}>
        <DialogTitle style={{ width: "400px" }}>Update</DialogTitle>
        <DialogContent>
          <DialogContentText>
            We will upload new media asset.
            <br />
            Please enter the asset name that you want.
          </DialogContentText>

          <Autocomplete
            freeSolo
            options={DeviceTypeList.map((option) => option.label)}
            style={{ marginBottom: "200px" }}
            onChange={onChangeOption}
            renderInput={(params) => (
              <TextField
                {...params}
                autoFocus
                margin="dense"
                label="Asset Name"
                type="text"
                name="new_asset_name"
                fullWidth
                disabled={dialog.upload}
                onChange={onChangeValue}
              />
            )}
          />
        </DialogContent>
        {!dialog.upload && (
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={onUpdateAsset}
              color="primary"
              disabled={data.new_asset_name.length === 0}
            >
              UPDATE
            </Button>
          </DialogActions>
        )}
      </Dialog>

      <LeftMenu>
        <ItemList>
          <List>
            {asset_list.map((item) => (
              <div
                key={item.md5}
                onMouseEnter={() => setOverItem(item.asset_name)}
                onMouseLeave={() => setOverItem("")}
              >
                <CustomListItem
                  onClick={() => onSelect(item.md5)}
                  $isPointer
                  $isSelect={item.is_selected}
                >
                  <div>{item.asset_name.replace(".info", "")}</div>

                  {item.asset_name === overItem && (
                    <IconContainer
                      onClick={() => onClickVideoPreview(item.md5)}
                    >
                      <OpenInNewIcon fontSize="small" />
                    </IconContainer>
                  )}
                </CustomListItem>
              </div>
            ))}
          </List>
        </ItemList>
        <CommandButton onClick={onAddList}>Add List</CommandButton>
        <CommandButton isLeft={true} onClick={onUpdate}>
          Update
        </CommandButton>
        <CommandButton
          isLeft={true}
          onClick={onRemove}
          style={{ left: "110px" }}
        >
          Remove
        </CommandButton>
      </LeftMenu>
      <RightMenu>
        <CommentContainer>
          <label>Comment : </label>
          <input
            name="collection_comment"
            style={{ flex: 1, marginLeft: "5px" }}
            value={data.collection_comment}
            onChange={onChangeValue}
          />
        </CommentContainer>
        <ItemList>
          <List>
            {data.collection_list.map((item) => (
              <CustomListItem key={item.asset_name}>
                <div>{item.asset_name.replace(".info", "")}</div>
              </CustomListItem>
            ))}
          </List>
        </ItemList>
        <CommandButton onClick={onCreateCollection}>
          Create Collection
        </CommandButton>
      </RightMenu>
    </Container>
  );
}

export default TabsAssetMenu;
