import { Config, Key } from "../sdlib/config";

const SET_SCHEDULE_ITEMS = "schedule/SET_SCHEDULE_ITEMS" as const;
const ADD_SCHEDULE_ITEMS = "schedule/ADD_SCHEDULE_ITEMS" as const;
const REMOVE_SCHEDULE_ITEM = "schedule/REMOVE_SCHEDULE_ITEM" as const;
const UPDATE_SCHEDULE_ITME = "schedule/UPDATE_SCHEDULE_ITME" as const;
const UPDATE_SCHEDULE_INFO = "schedule/UPDATE_SCHEDULE_INFO" as const;

export type ScheduleData = {
  items: ScheduleItemType[];
  schedule_version: number;
  schedule_start_time: string;
  schedule_end_time: string;
  gmt_value: string;
};

export const setScheduleItems = (items: ScheduleItemType[]) => ({
  type: SET_SCHEDULE_ITEMS,
  items,
});

export const addScheduleItems = (items: ScheduleItemType[]) => ({
  type: ADD_SCHEDULE_ITEMS,
  items,
});

export const removeScheduleItem = (id: number) => ({
  type: REMOVE_SCHEDULE_ITEM,
  id,
});

export const updateScheduleItem = (data: ScheduleItemType) => ({
  type: UPDATE_SCHEDULE_ITME,
  data,
});

export const updateScheduleInfo = (
  schedule_version: number,
  start_time: string,
  end_time: string,
  gmt_value: string
) => ({
  type: UPDATE_SCHEDULE_INFO,
  schedule_version,
  start_time,
  end_time,
  gmt_value,
});

const initialState: ScheduleData = {
  items: [],
  schedule_version: 0,
  schedule_start_time: "",
  schedule_end_time: "",
  gmt_value: "",
};

export type ScheduleItemType = {
  id: number;
  alias?: string;
  type: string;
  duration?: number;
  transitionTime?: number;
  res_name: string;
};

type ScheduleAction =
  | ReturnType<typeof setScheduleItems>
  | ReturnType<typeof addScheduleItems>
  | ReturnType<typeof removeScheduleItem>
  | ReturnType<typeof updateScheduleItem>
  | ReturnType<typeof updateScheduleInfo>;

export default function schedule(state = initialState, action: ScheduleAction) {
  switch (action.type) {
    case SET_SCHEDULE_ITEMS:
      return {
        ...state,
        items: action.items,
      };
    case ADD_SCHEDULE_ITEMS:
      return {
        ...state,
        items: state.items.concat(...action.items),
      };
    case REMOVE_SCHEDULE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.id),
      };
    case UPDATE_SCHEDULE_ITME:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id === action.data.id) {
            return {
              id: action.data.id,
              alias: action.data.alias,
              res_name: action.data.res_name,
              duration: action.data.duration,
              transitionTime: action.data.transitionTime,
              type: action.data.type,
            };
          } else return item;
        }),
      };
    case UPDATE_SCHEDULE_INFO:
      return {
        ...state,
        schedule_version: action.schedule_version,
        schedule_start_time: action.start_time,
        schedule_end_time: action.end_time,
        gmt_value: action.gmt_value,
      };
    default:
      return state;
  }
}
