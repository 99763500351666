import { List } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { loadMultipleScheduleJson } from "../api/rest";
import { RootState } from "../modules";
import { setDeviceList } from "../modules/device";
import { dlog } from "../sdlib/sdutil";
import { CustomListItem } from "./TabsDeviceMenu";
import { CustomChip } from "./TopMenu";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftMenu = styled.div`
  flex: 1;
  position: relative;
`;

const RightMenu = styled.div`
  flex: 1;
  position: relative;
`;

const ItemList = styled.ul`
  position: absolute;
  background-color: #f2f2f2;
  left: 25px;
  right: 25px;
  top: 60px;
  bottom: 60px;
  padding: 20px;
  border-radius: 15px;
  overflow-y: auto;

  float: left;
  clear: both;
  margin: 1em 0;
`;

function TabsShowScheduleMenu() {
  const [json, setJson] = useState({});
  const { devices } = useSelector((state: RootState) => state.device);
  const dispatch = useDispatch();

  useEffect(() => {
    const new_clienets = devices.map((item) => {
      return { ...item, is_selected: false };
    });
    dispatch(setDeviceList(new_clienets));
  }, []);

  const onSelectDevice = (region: string) => {
    const new_clienets = devices.map((item) => {
      return item.region === region
        ? { ...item, is_selected: !item.is_selected }
        : { ...item, is_selected: false };
    });
    dispatch(setDeviceList(new_clienets));

    const selected = new_clienets.filter((item) => {
      return item.is_selected;
    });
    if (selected.length > 0) {
      const resource = selected[0].res_type;
      dlog("Resource", resource);

      loadMultipleScheduleJson().then((response) => {
        const data = response.data.data as any[];
        dlog("RR", data);

        let index = -1;
        for (let i = data.length - 1; i >= 0; i--) {
          const platform = data[i].platform.trim();
          if (resource === platform) {
            index = i;
            break;
          }
        }

        if (index != -1) {
          const schedule = data[index];
          setJson(schedule);
        } else {
          setJson({});
        }
      });
    } else {
      setJson({});
    }
  };

  return (
    <Container>
      <LeftMenu>
        <ItemList>
          <List>
            {devices.map((item) => (
              <CustomListItem
                key={item.region}
                $isSelect={item.is_selected}
                onClick={() => onSelectDevice(item.region)}
              >
                <div>{item.client_id}</div>

                {item.res_type != undefined && (
                  <CustomChip
                    label={item.res_type}
                    color="secondary"
                    style={{ backgroundColor: "#666666", marginLeft: "auto" }}
                  />
                )}
              </CustomListItem>
            ))}
          </List>
        </ItemList>
      </LeftMenu>
      <RightMenu>
        <ItemList>
          <pre>
            <code>{JSON.stringify(json, null, 4)}</code>
          </pre>
        </ItemList>
      </RightMenu>
    </Container>
  );
}

export default TabsShowScheduleMenu;
