import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  TextField,
} from "@mui/material";
import React, { RefObject, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { dlog } from "../sdlib/sdutil";
import "jsoneditor/dist/jsoneditor.css";
import JSONEditor from "jsoneditor";
import { loadLightJson, updateLightJson } from "../api/rest";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LeftMenu = styled.div`
  flex: 1;
  position: relative;
`;

const JsonContainer = styled.div`
  position: absolute;
  background-color: #f2f2f2;
  left: 25px;
  right: 25px;
  top: 70px;
  bottom: 70px;
  /* padding: 20px; */
  /* border-radius: 15px; */
  overflow-y: auto;

  float: left;
  clear: both;
  margin: 1em 0;

  font-size: 20px;
  /* border: 1px solid lightgrey; */
  overflow-y: auto;
  margin: 0px;
  /* width: 50vw;
  height: calc(100vh - 250px); */

  * {
    -webkit-user-select: text;
  }
`;

const CommandButton = styled.div<{ isLeft?: boolean }>`
  cursor: pointer;
  position: absolute;
  bottom: 25px;
  left: ${(props) => (props.isLeft ? "25px" : "none")};
  right: ${(props) => (!props.isLeft ? "25px" : "none")};
  padding: 10px;
  width: fit-content;
  height: fit-content;
  background-color: #d8e2dc;
`;

let jsoneditor: JSONEditor | null = null;
function TabsLightMenu() {
  const jsonDivRef: RefObject<HTMLDivElement> = useRef(null);
  const [json, setJson] = useState({});

  const onApply = () => {
    dlog("apply", json);

    updateLightJson(json)
      .then(() => {
        alert("Update Success");
      })
      .catch(() => {
        alert("Update Fail");
      });
  };

  useEffect(() => {
    loadLightJson().then((response) => {
      let data = response.data;

      if (jsoneditor == null) {
        let container = jsonDivRef.current as HTMLDivElement;
        jsoneditor = new JSONEditor(container, {
          mode: "code",
          modes: ["code", "tree"],
          onValidate: (json) => {
            // dlog("JJ", json);
            setJson(json);
            const errors = [] as any[];
            return errors;
          },
          onChangeText: (text) => {
            // dlog("text", text);
            if (text.length === 0) {
              setJson({});
            }
          },
        });

        if (data != undefined) {
          jsoneditor.set(data);
          setJson(data);
        }
      }
    });

    return () => {
      if (jsoneditor != null) {
        jsoneditor.destroy();
        jsoneditor = null;
      }
    };
  }, []);

  return (
    <Container>
      <LeftMenu>
        <JsonContainer
          className="jsoneditor-react-container"
          ref={jsonDivRef}
        />

        <CommandButton onClick={onApply}>Apply</CommandButton>
      </LeftMenu>
    </Container>
  );
}

export default TabsLightMenu;
