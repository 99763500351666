import CryptoJS from "crypto-js";

const CRYPTO_KEY = "IMFINESDCRYPTO";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function encryptText(value: string) {
  const cipher = CryptoJS.AES.encrypt(value, CRYPTO_KEY).toString();
  return cipher;
}

export function decryptText(value: string) {
  var bytes = CryptoJS.AES.decrypt(value, CRYPTO_KEY);
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}

export function isDebug() {
  return process.env.NODE_ENV === "development";
}

export const dlog = (...args: Array<any>) => {
  console.log(
    "%c%s",
    "color: black; background: steelblue;",
    "IMFINE",
    ...args
  );
};

export function pad(n: number, digits: number = 2) {
  var zero = "";
  const num = n.toString();
  if (num.length < digits) {
    for (let i = 0; i < digits - num.length; i++) zero += "0";
  }
  return zero + n;
}

export function getRandomArbitrary(min: number, max: number) {
  const value = Math.random() * (max - min) + min;
  return Math.floor(value);
}

export function isDesktop() {
  const filter = "win16|win32|win64|mac";
  if (navigator.platform) {
    if (filter.indexOf(navigator.platform.toLowerCase()) < 0) {
      return false;
    } else {
      return true;
    }
  }
}

export function isIOSDevice() {
  return !!navigator.platform.match(/iPhone|iPod/);
}

export function launchFullScreen(element: any) {
  if (element.requestFullScreen) {
    element.requestFullScreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  }
}

export function removeSpecialCharacter(str: string) {
  var reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi;
  if (reg.test(str)) {
    return str.replace(reg, "");
  } else {
    return str;
  }
}

export function isOnline() {
  return !!navigator.onLine;
}
